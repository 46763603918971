module.exports = {
    "metadata": {
        "name": "Journalistes Solidaires Chartes",
        "short_name": "JS_Chartes",
        "description": "Les chartes de Journalistes Solidaires",
        "language": "fr",
        "url": "https://js-chartes.netlify.app/",
        "pathPrefix": "/",
        "gaTrackingId": null,
        "siteImage": null,
        "favicon": "/assets/favicon-js.jpg",
        "themeColor": "#0066cc"
    },
    "header": {
        "logo": "/assets/favicon-js.jpg",
        "logoLink": "/",
        "helpUrl": "",
        "links": [
            {
                "text": "BooGi",
                "link": "https://github.com/filipowm/boogi",
                "external": true
            },
            {
                "text": "Gatsby",
                "link": "https://www.gatsbyjs.org",
                "external": true
            },
            {
                "text": "Docs",
                "link": "/",
                "external": false
            }
        ],
        "enabled": true
    },
    "sidebar": {
        "enabled": true,
        "ignoreIndex": false,
        "forcedNavOrder": [],
        "expanded": [],
        "groups": [
            {
                "order": 1,
                "path": "/onboarding",
                "title": ":rocket: Embarquement !"
            },
            {
                "order": 2,
                "path": "/chartes",
                "title": ":scroll: Les chartes"
            }
        ],
        "links": [],
        "poweredBy": {}
    },
    "pwa": {
        "manifest": {
            "name": "BooGi",
            "short_name": "BooGi",
            "start_url": "/",
            "background_color": "#6b37bf",
            "theme_color": "#6b37bf",
            "display": "minimal-ui",
            "crossOrigin": "anonymous",
            "icon": "static/assets/logo.png",
            "description": "Les chartes de Journalistes Solidaires",
            "cache_busting_mode": "none",
            "include_favicon": false,
            "lang": "fr"
        },
        "enabled": true
    },
    "social": {
        "facebook": "https://www.facebook.com/journalistessolidaires/",
        "github": "",
        "gitlab": "",
        "instagram": "",
        "linkedin": "",
        "mail": "",
        "gmail": "",
        "slack": "",
        "twich": "",
        "twitter": "https://twitter.com/journalistessol",
        "youtube": "https://www.youtube.com/channel/UCYnfvQblCm1OWp1o4DHKbjg"
    },
    "features": {
        "editOnRepo": {
            "editable": true,
            "location": "https://github.com/Journalistes-Solidaires/chartes",
            "type": "github"
        },
        "search": {
            "enabled": true,
            "indexName": "myapp-test-idx",
            "algoliaAppId": "VPGPWZDGMB",
            "algoliaSearchKey": "eeebdcecb72be93b016243e559b6f0dd",
            "algoliaAdminKey": "29c3c30f34cf2b5a6cd1d75e998be8ed",
            "excerptSize": 20000,
            "engine": "localsearch",
            "placeholder": "Search",
            "startComponent": "icon",
            "debounceTime": 380,
            "snippetLength": 23,
            "hitsPerPage": 10,
            "showStats": true,
            "localSearchEngine": {
                "encode": "advanced",
                "tokenize": "full",
                "threshold": 2,
                "resolution": 30,
                "depth": 20
            },
            "pagination": {
                "enabled": true,
                "totalPages": 10,
                "showNext": true,
                "showPrevious": true
            }
        },
        "toc": {
            "show": true,
            "depth": 3
        },
        "previousNext": {
            "enabled": true,
            "arrowKeyNavigation": true
        },
        "scrollTop": true,
        "showMetadata": true,
        "propagateNetlifyEnv": true,
        "pageProgress": {
            "enabled": false,
            "excludePaths": [
                "/"
            ],
            "height": 3,
            "prependToBody": false,
            "color": "#A05EB5"
        },
        "mermaid": {
            "language": "mermaid",
            "theme": "dark",
            "options": {},
            "width": 300,
            "height": 300
        },
        "rss": {
            "enabled": true,
            "showIcon": true,
            "title": "My RSS feed",
            "copyright": "",
            "webMaster": "M",
            "managingEditor": "",
            "categories": [
                "GatsbyJS",
                "Docs"
            ],
            "ttl": "60",
            "matchRegex": "^/",
            "outputPath": "/rss.xml",
            "generator": "gidocs"
        },
        "darkMode": {
            "enabled": true,
            "default": false
        },
        "publishDraft": false,
        "fullScreenMode": {
            "enabled": false,
            "hideHeader": true,
            "hideToc": true,
            "hideSidebar": true
        },
        "features": {
            "search": {
                "enabled": "true,",
                "indexName": "Chartes JS",
                "engine": "algolia",
                "excerptSize": 10000,
                "placeholder": "Rechercher...",
                "startComponent": "input",
                "debounceTime": "380,",
                "snippetLength": "22,",
                "hitsPerPage": "10,",
                "showStats": "true,",
                "pagination": {
                    "enabled": "true,",
                    "totalPages": "10,",
                    "showNext": "true,",
                    "showPrevious": true
                }
            }
        }
    },
    "search": {
        "enabled": true,
        "startComponent": "input",
        "placeholder": "Rechercher...",
        "pagination": {
            "enabled": true
        }
    }
};